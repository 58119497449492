<template>
  <y-card>

    <y-card-subtitle>
      Réglages
    </y-card-subtitle>

    <div class="text border-b">
      <p class="p-4">
        #Todo
      </p>
    </div>

  </y-card>
</template>

<script>
// import { computed } from 'vue';
// import { useStore } from 'vuex';

import YCard from '@/UI/YCard.vue';
import YCardSubtitle from '@/UI/YCardSubtitle.vue';

export default {

  components: {
    YCard,
    YCardSubtitle,
  },

  setup() {
    // const store = useStore();
    return {};
  },

};
</script>
